/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

h1, h2, h3 {
  color: #444;
  text-align: center;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Container */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Input Styles */
input[type="text"],
input[type="date"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: calc(100% - 20px);
  margin-top: 5px;
  display: block;
}

input[type="text"]:focus,
input[type="date"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button Styles */
button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Error Messages */
.error-message {
  color: #d9534f;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
}

/* Results Section */
.results {
  margin-top: 20px;
}

.results h2 {
  margin-bottom: 10px;
  color: #007bff;
}

.results p {
  font-size: 1.1rem;
  color: #444;
}

/* Chart Container */
.chart-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive Chart */
.recharts-responsive-container {
  width: 100%;
  height: 400px;
}
